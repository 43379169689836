<template>
  <div class="row justify-center bg-grey-3">
    <q-card style="width:300px">
      <q-card-section class="q-gutter-md">
        <q-select
          outlined
          stack-label
          label="Kelas"
          :options="kelas"
          v-model="selKelas"
          @input="getListSiswa"
        ></q-select>
        <div class="row">
          <q-select
            class="col-10"
            outlined
            stack-label
            label="Siswa"
            :options="siswa"
            v-model="selSiswa"
            :disable="selKelas.value == null"
            @input="getListPrestasi"
          ></q-select>
          <q-btn
            class="col-2"
            flat
            icon="visibility"
            color="primary"
            @click="showDialogPrestasi"
            :disable="selSiswa.value == null || selSiswa.value == -1"
          ></q-btn>
        </div>
      </q-card-section>
      <q-card-actions>
        <q-btn
          unelevated
          class="full-width"
          color="primary"
          icon="add"
          label="Tambah Prestasi"
          v-can="['WALAS']"
          :disable="selKelas.value == null"
          :to="`/penilaianinputprestasi/${selKelas.value}/${selSiswa.value}/-1`"
        ></q-btn>
      </q-card-actions>
      <q-markup-table
        wrap-cells
        dense
        separator="horizontal"
        class="stickyTable bg-grey-4"
        style="height: calc(100vh - 300px);"
      >
        <thead>
          <tr>
            <th>Nama Siswa</th>
            <th>Prestasi</th>
          </tr>
        </thead>
        <tbody class="bg-white">
          <tr
            v-for="(value, i) in prestasi"
            :key="i"
            @click="
              $router.push(
                `/penilaianinputprestasi/${selKelas.value}/${selSiswa.value}/${value.id}`
              )
            "
          >
            <td>{{ value.nama_siswa }}</td>
            <td>{{ value.nama }}</td>
          </tr>
        </tbody>
      </q-markup-table>
    </q-card>
  </div>
</template>

<script>
import DialogListPrestasi from "@/components/DialogListPrestasi";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      user: {},

      kelas: [],
      selKelas: { label: null, value: null },

      siswa: [],
      selSiswa: { label: null, value: null },

      prestasi: [],
    };
  },
  async mounted() {
    await this.getUser();
    await this.getListKelas();
  },
  methods: {
    ...mapGetters(["getCurrentUser"]),
    async getUser() {
      this.user = await this.getCurrentUser();
    },
    showDialogPrestasi() {
      this.$q.dialog({
        component: DialogListPrestasi,
        parent: this,
        siswa: this.selSiswa,
      });
    },
    show() {
      this.$q.notify({
        message: "Halo",
        color: "primary",
      });
    },
    async getListKelas() {
      let resp = await this.$http.get(
        `/penilaian/prestasi/getkelas/${localStorage.getItem("jenjang")}/${
          this.user.id
        }/${this.user.is_kepala_sekolah}/${this.user.is_super_user}`
      );
      this.kelas = resp.data;
      if (parseInt(this.$route.params.id_kelas) != -1) {
        this.selKelas = this.kelas.find(
          (item) => item.value == this.$route.params.id_kelas
        );
        await this.getListSiswa();
        if (parseInt(this.$route.params.id_siswa) != -1) {
          this.selSiswa = this.siswa.find(
            (item) => item.value == this.$route.params.id_siswa
          );
          await this.getListPrestasi();
        }
      }
    },
    async getListSiswa() {
      let resp = await this.$http.get(
        "/penilaian/riwayat_absen/getsiswa/" + this.selKelas.value
      );
      resp.data.unshift({ label: "SEMUA SISWA", value: -1 });
      this.siswa = resp.data;
      this.selSiswa = this.siswa[0];
      this.getListPrestasi();
    },
    async getListPrestasi() {
      if (this.selSiswa.value == null) return;
      let resp = await this.$http.get(
        `/prestasi/list/getlist/${this.selSiswa.value}/${
          this.selKelas.value
        }/${localStorage.getItem("id_tahun_ajaran")}`
      );
      this.prestasi = resp.data;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/quasar.sass";
</style>
